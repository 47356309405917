import { get } from 'lodash';

/**
 * Values are the keys used in .env* files
 */
export enum ConfigKey {
  APP_VERSION = 'APP_VERSION',
  HTTP_API_URL = 'HTTP_API_URL',
  WS_API_URL = 'WS_API_URL',
  GQL_API_URL = 'GQL_API_URL',
  MAPBOX_STYLE_DEFAULT = 'MAPBOX_STYLE_DEFAULT',
  MAPBOX_STYLE_SATELLITE = 'MAPBOX_STYLE_SATELLITE',
  MAPBOX_STYLE_DARK = 'MAPBOX_STYLE_DARK',
  MAPBOX_STYLE_STOPP = 'MAPBOX_STYLE_STOPP',
  MAPBOX_STYLE_STOPPSAT = 'MAPBOX_STYLE_STOPPSAT',
  MAPBOX_TOKEN = 'MAPBOX_TOKEN',
  MAPBOX_DEV_TOKEN = 'MAPBOX_DEV_TOKEN',
  KEYCLOAK_REALM = 'KEYCLOAK_REALM',
  KEYCLOAK_AUTH_SERVER_URL = 'KEYCLOAK_AUTH_SERVER_URL',
  KEYCLOAK_SSL_REQUIRED = 'KEYCLOAK_SSL_REQUIRED',
  KEYCLOAK_CLIENT = 'KEYCLOAK_CLIENT',
  KEYCLOAK_PUBLIC_CLIENT = 'KEYCLOAK_PUBLIC_CLIENT',
  KEYCLOAK_CONFIDENTIAL_PORT = 'KEYCLOAK_CONFIDENTIAL_PORT',
  SENTRY_DSN = 'SENTRY_DSN',
}

export class EnvironmentConfigurationService {
  private static hasWindowObject = () => {
    return !!get(window, '_env_');
  };

  private static getFromEnv(key: ConfigKey) {
    if (key === ConfigKey.APP_VERSION) {
      return process.env.APP_VERSION;
    }
    // Workaround because parcel does not allow accessing .env variables with
    // a process.env[key] syntax. Trying to access env variables like that always
    // returns `undefined`
    // @see https://en.parceljs.org/env.html
    switch (key) {
      case ConfigKey.HTTP_API_URL:
        return process.env.HTTP_API_URL;
      case ConfigKey.WS_API_URL:
        return process.env.WS_API_URL;
      case ConfigKey.GQL_API_URL:
        return process.env.GQL_API_URL;
      case ConfigKey.MAPBOX_STYLE_DEFAULT:
        return process.env.MAPBOX_STYLE_DEFAULT;
      case ConfigKey.MAPBOX_STYLE_SATELLITE:
        return process.env.MAPBOX_STYLE_SATELLITE;
      case ConfigKey.MAPBOX_STYLE_DARK:
        return process.env.MAPBOX_STYLE_DARK;
      case ConfigKey.MAPBOX_STYLE_STOPP:
        return process.env.MAPBOX_STYLE_STOPP;
      case ConfigKey.MAPBOX_STYLE_STOPPSAT:
        return process.env.MAPBOX_STYLE_STOPPSAT;
      case ConfigKey.MAPBOX_TOKEN:
        return process.env.MAPBOX_TOKEN;
      case ConfigKey.MAPBOX_DEV_TOKEN:
        return process.env.MAPBOX_DEV_TOKEN;
      case ConfigKey.KEYCLOAK_REALM:
        return process.env.KEYCLOAK_REALM;
      case ConfigKey.KEYCLOAK_AUTH_SERVER_URL:
        return process.env.KEYCLOAK_AUTH_SERVER_URL;
      case ConfigKey.KEYCLOAK_SSL_REQUIRED:
        return process.env.KEYCLOAK_SSL_REQUIRED;
      case ConfigKey.KEYCLOAK_CLIENT:
        return process.env.KEYCLOAK_CLIENT;
      case ConfigKey.KEYCLOAK_PUBLIC_CLIENT:
        return process.env.KEYCLOAK_PUBLIC_CLIENT;
      case ConfigKey.KEYCLOAK_CONFIDENTIAL_PORT:
        return process.env.KEYCLOAK_CONFIDENTIAL_PORT;
      case ConfigKey.SENTRY_DSN:
        return process.env.SENTRY_DSN;
    }
  }

  private static getFromWindow(key: ConfigKey) {
    return get(window, `_env_[${key}]`);
  }

  static get(key: ConfigKey) {
    const { isInDevelopmentEnvironment, getFromEnv, getFromWindow } = EnvironmentConfigurationService;

    // Always read app version from env because it's set in code by bundler
    if (isInDevelopmentEnvironment() || key === ConfigKey.APP_VERSION) {
      return getFromEnv(key);
    }
    return getFromWindow(key);
  }

  static isInDevelopmentEnvironment() {
    return ['development', 'test'].includes(process.env.NODE_ENV) || !EnvironmentConfigurationService.hasWindowObject();
  }

  static getKeycloakConfiguration() {
    const { get } = EnvironmentConfigurationService;

    return {
      url: get(ConfigKey.KEYCLOAK_AUTH_SERVER_URL),
      realm: get(ConfigKey.KEYCLOAK_REALM),
      resource: get(ConfigKey.KEYCLOAK_CLIENT),
    };
  }

  static getMapboxStyles() {
    const { get } = EnvironmentConfigurationService;

    return {
      default: get(ConfigKey.MAPBOX_STYLE_DEFAULT),
      satellite: get(ConfigKey.MAPBOX_STYLE_SATELLITE),
      dark: get(ConfigKey.MAPBOX_STYLE_DARK),
      stopp: get(ConfigKey.MAPBOX_STYLE_STOPP),
      stoppSat: get(ConfigKey.MAPBOX_STYLE_STOPPSAT),
    };
  }
}
